<template>
    <a-card style="background-color: #fff;">
        <span style="color:red;position:absolute;right:3%;top:45px;">sku无法创建商品，请前去【我的spu商品】进行创建</span>
        <a-tabs default-active-key="" @change="callback">
            <a-tab-pane key="" tab="全部sku商品">
            </a-tab-pane>
            <a-tab-pane key="1" tab="审核中">
            </a-tab-pane>
            <a-tab-pane key="2" tab="审核通过">
            </a-tab-pane>
            <a-tab-pane key="3" tab="审核未通过">
            </a-tab-pane>
        </a-tabs>
        <div>
            <a-form layout="horizontal">
                <div :class="advanced ? null : 'fold'">
                    <a-row>
                        <a-col :md="8" :sm="24" style="max-width: 350px;">
                            <a-form-item label="商品名称" :labelCol="{ span: 5 }" :wrapperCol="{ span: 17, offset: 1 }">
                                <a-input v-model="queryData.name" placeholder="请输入商品名称"></a-input>
                            </a-form-item>
                        </a-col>
                        <a-col :md="8" :sm="24" style="max-width: 450px;">
                            <a-form-item label="供应商自查sku编码" :labelCol="{ span: 7 }" :wrapperCol="{ span: 12, offset: 1 }">
                                <a-input v-model="queryData.supplierCode" placeholder="请输入供应商自查sku编码"></a-input>
                            </a-form-item>
                        </a-col>
                        <a-col :md="8" :sm="24" style="max-width: 350px;">
                            <a-form-item label="商品品类" :labelCol="{ span: 5 }" :wrapperCol="{ span: 17, offset: 1 }">
                                <a-cascader v-model="classifyList" :options="categoryOptions" :load-data="getCategoryList" placeholder="请选择品类" change-on-select  @change="categoryChange" />
                                <!-- <a-select allowClear placeholder="请选择商品品类" v-model="queryData.certificateType"
                                    option-label-prop="label">
                                    <a-select-option v-for="item in categoryList" :key="item.id" :value="item.id"
                                        :label="item.name">
                                        {{ item.name }}
                                    </a-select-option>
                                </a-select> -->
                            </a-form-item>
                        </a-col>
                        <!-- <a-col :md="8" :sm="24" style="max-width: 350px;">
                            <a-form-item label="审核状态" :labelCol="{ span: 5 }" :wrapperCol="{ span: 17, offset: 1 }">
                                <a-select allowClear placeholder="请选择审核状态" v-model="queryData.auditStatus"
                                    option-label-prop="label">
                                    <a-select-option v-for="item in categoryList" :key="item.id" :value="item.id"
                                        :label="item.name">
                                        {{ item.name }}
                                    </a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-col> -->
                        <a-col :md="8" :sm="24" style="max-width: 350px;">
                            <a-form-item label="sku编号" :labelCol="{ span: 5 }" :wrapperCol="{ span: 17, offset: 1 }">
                                <a-input v-model="queryData.sku" placeholder="请输入sku编号"></a-input>
                            </a-form-item>
                        </a-col>
                        <a-col :md="8" :sm="24" style="max-width: 350px;">
                            <a-form-item label="创建时间" :labelCol="{ span: 5 }" :wrapperCol="{ span: 17, offset: 1 }">
                                <a-range-picker :value="dateValue" :ranges="ranges" @change="dateChange" />
                            </a-form-item>
                        </a-col>
                        <a-col :md="8" :sm="24" style="max-width: 350px;">
                            <a-button @click="reset" style="margin-left: 10px;">重置</a-button>
                            <a-button type="primary" style="margin-top: 4px;margin-left: 10px;"
                                @click="search">查询</a-button>
                        </a-col>
                    </a-row>
                  
                    <a-row>
                    </a-row>
                    <div style="margin-top: 10px;font-size: 15px;margin-left: 10px;">显示第 {{ total > 0 ? 1 : 0 }} 至 {{
                    queryData.size > total ? total : queryData.size }} 项结果，共 {{ total }} 项</div>
                </div>
            </a-form>
        </div>
        <div>
            <a-table :scroll="{ x: 1500 }" bordered :columns="columns" :dataSource="dataSource" :loading="tableLoading"
                :pagination="false">
                <template slot="fileUrl" slot-scope="record,text">
                    <div v-for="item in text.qualificationFileList" :key="item.id">
                        <a v-if="item.fileUrl" @click="fileLink(item.fileUrl)">{{ item.certificateTypeDesc }}</a> 
                    </div>
                </template>

                <template slot="supplierCode" slot-scope="record,text">
                    <span>{{ text.supplierCode ? text.supplierCode : '暂无'}}</span>
                </template>

                
                <template slot="qualificationStatusDesc" slot-scope="record,text">
                    <a @click="toQualifi" v-if="text.qualificationStatus == 1">{{ record }}</a>
                    <a @click="toQualifi" v-if="text.qualificationStatus == 2" style="color:red">{{ record }}</a>
                    <a @click="toQualifi" v-if="text.qualificationStatus == 3" style="color:#dba000;">{{ record }}</a>
                    <a @click="toQualifi" v-if="text.qualificationStatus == 4" >{{ record }}</a>
                    <a @click="toQualifi" v-if="text.qualificationStatus == 5" >{{ record }}</a>
                    <a @click="toQualifi" v-if="text.qualificationStatus == 6" >{{ record }}</a>
                </template>
                <template slot="name" slot-scope="record,text">
                    <a @click="toDetails(text.id)">{{ record }}</a>
                </template>
                <div slot="action" slot-scope="record">
                    <a v-if="record.qualificationStatus != 2" @click="showModal(record)" style="margin-left: 10px;">库存</a>
                     <!-- 审核状态auditStatus 0.待提交审核 1.审核中 2.审核通过 3.审核未通过 -->
                    <!-- 商品状态status      0. 草稿 1 上架 2 下架 -->
                    <!-- <span v-if="record.auditStatus != 1 && record.auditStatus != 0 && record.qualificationStatus != 2 && record.auditStatus != 4"> -->
                    <span>
                        <!-- 审核通过 -->
                        <a @click="editStatus(record)" style="margin-left: 10px;" v-if="record.auditStatus == 2 && record.status == 1">下架</a>
                        <a @click="editStatus(record)" style="margin-left: 10px;" v-if="record.auditStatus == 2 && record.status == 2">上架</a>

                        <!-- 审核未通过 -->
                        <a @click="editStatus(record)" style="margin-left: 10px;" v-if="record.auditStatus == 5 && record.status == 1">下架</a>
                    </span>
                </div>
                <template slot="pic" slot-scope="record">
                    <img :src="record" style="width: 40px;max-height: 40px;" alt="">
                </template>
                <template slot="auditStatusDesc" slot-scope="record,text">
                    <span v-if="text.auditStatus == 0" style="color:#00dbac">{{ record }}</span>
                    <span v-if="text.auditStatus == 1" style="color:#dba000">{{ record }}</span>
                    <span v-if="text.auditStatus == 2" style="color:#006be8">{{ record }}</span>
                    <span v-if="text.auditStatus == 3" style="color:#cd0000">{{ record }}</span>
                    <span v-if="text.auditStatus == 4" style="color:#dba000">{{ record }}</span>
                    <span v-if="text.auditStatus == 5" style="color:#cd0000">{{ record }}</span>
                </template>
                <template slot="createTime" slot-scope="record">{{ formatDateMin(record) }}</template>
                <template slot="modifyTime" slot-scope="record"> {{ formatDateMin(record) }}</template>
            </a-table>
            <div class="page-box">
                <a-pagination :total="total" :current="queryData.page + 1" :page-size-options="pageSizeOptions" :pageSize="queryData.size" @change="handlePageChange" @showSizeChange="onShowSizeChange" show-size-changer />
            </div>
        </div>
        

        <a-modal v-model="openKC" title="变更库存" @ok="handleCk">
          <p>现有库存：{{ oldstock }}个</p>
          <p>
            变更为 <a-input v-model="stock" style="width:60px;" size="small" /> 个
          </p>
        </a-modal>

        <a-modal v-model="openActionLog" title="操作日志" :footer="null">
            <a-table bordered :columns="columnsActionLog" :dataSource="dataSourceActionLog" :loading="tableLogActionLoading"
                :pagination="false">
                <template slot="createTime" slot-scope="record">{{ formatDateMin(record) }}</template>
            </a-table>
            <div class="page-box">
                <a-pagination :total="logActionTotal" :current="logActionQuery.page + 1" :page-size-options="pageSizeOptions" :pageSize="logActionQuery.size" @change="handlePageChange" @showSizeChange="onShowSizeChange" show-size-changer />
            </div>
        </a-modal>

        <a-modal v-model="downOpen" title="下架原因" @ok="handleDown">
            <a-textarea v-model="reason" placeholder="请输入下架原因"></a-textarea>
        </a-modal>
    </a-card>
</template>

<script>
import moment from 'moment';
import 'moment/locale/zh-cn';
import { request } from '@/utils/request'
const columns = [
    { title: '序号',width:80, customRender: (text, record, index) => index + 1, align: 'center' },
    { title: '商品名称',dataIndex: 'name', align: 'center' ,scopedSlots: { customRender: 'name' }},
    { title: 'sku编号',dataIndex: 'sku', align: 'center' },
    { title: '规格',dataIndex: 'specsAttribute', align: 'center' },
    { title: '规格图',dataIndex: 'pic', align: 'center', scopedSlots: { customRender: 'pic' } },
    { title: '库存',dataIndex: 'stock', align: 'center' },
    { title: '商品品类', dataIndex: 'classify', align: 'center', },
    { title: '审核状态', dataIndex: 'auditStatusDesc', align: 'center' ,scopedSlots: { customRender: 'auditStatusDesc' } },
    { title: '上下架状态', dataIndex: 'statusDesc', align: 'center',},
    { title: '相关资质', dataIndex: 'qualificationStatusDesc', align: 'center', scopedSlots: { customRender: 'qualificationStatusDesc' } },
    { title: '供应商自查sku编码', dataIndex: 'supplierCode', align: 'center' ,scopedSlots: { customRender: 'supplierCode' }  },
    { title: '创建时间',  dataIndex: 'createTime', align: 'center', scopedSlots: { customRender: 'createTime' } },
    { title: '最后更新时间', dataIndex: 'modifyTime', align: 'center', scopedSlots: { customRender: 'modifyTime' } },
    { title: '操作',  width:180,align: 'center',fixed:"right", scopedSlots: { customRender: 'action' } },
]
export default {
    name: 'QueryList',
    data() {
        return {
            moment,
         
            advanced: true,
            columns: columns,
            dataSource: [],
            selectedRows: [],
            current: 1,
            dateValue: '',
            total: 0,
            // 筛选
            queryData: {
                page: 0,
                size: 10,
            },
            pageSizeOptions: ['10', '30', '40', '50', '100', '500', '1000'],
            tableLoading: false,
           
            currentPage: 1,
           
            ranges: {
                '今天': [moment(), moment()],
                '昨天': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                '当月': [moment().startOf('month'), moment().endOf('month')],
                '上月': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            },
            isModalVisible: false,
            confirmLoading: false,
            formData: {},
            categoryList: [{ id: 0, name: '草稿' }, { id: 1, name: '审核中' }, { id: 2, name: '审核通过' }, { id: 3, name: '审核未通过' }],
            userInfo:{},
            // 日志
            logQuery:{
                page:0,
                size:10
            },
            columnsLog:[],
            dataSourceLog:[ 
                { title: '审核状态', dataIndex: 'typeDesc', align: 'center', },
                { title: '操作时间',  dataIndex: 'createTime', align: 'center', scopedSlots: { customRender: 'createTime' } },
                { title: '操作人', dataIndex: 'skuCount', align: 'center', },
                { title: '反馈详情', dataIndex: 'skuCount', align: 'center', },
            ],
            tableLogLoading:false,
            openLog:false,
            logTotal:0,
            
            // 操作日志
            openActionLog:false,
            dataSourceActionLog:[],
            columnsActionLog:[],
            tableLogActionLoading:false,
            logActionQuery:{
                page:0,
                size:10
            },
            logActionTotal:0,



            // 库存
            openKC:false,
            oldstock:0,
            stock:"",
            editId:"",


            // 下架
            downOpen:false,
            reason:"",

            // 品类
            categoryOptions:[{value:'1',label:'商城',isLeaf:false}],
            classifyList:[]
        }
    },
    mounted() {
        this.getData()
    },
    methods: {
        toQualifi() {
            this.$router.push({
                path: "/resources/resources_credentia",
            })
        },
        toDetails(id) {
            this.$router.push({
                path: "/product/product_sku_formDetails",
                query: {
                    id: id,
                    isDetails:true
                }
            })
        },
        categoryChange(value){
            this.queryData.classifyList = value;
        },
        getCategoryList(value){
            let id =  value[value.length-1].value
            let url = process.env.VUE_APP_API_BASE_URL + 'srm/classify/list/' + id + '/0'
            if(id != 1) {
                url = process.env.VUE_APP_API_BASE_URL + 'srm/classify/list/' + 1 + '/' + id
            }
            request(url, 'get').then((res) => {
                let data = res.data.data;
                console.log(data,'data')
                data.forEach(item=>{
                    item.value = item.id
                    item.label = item.name
                    if(item.level < 3) {
                        item.isLeaf = false
                    }
                })
                if(value.length != 3) {
                    if(id == 1) {
                        this.categoryOptions.forEach(item=>{
                            if(item.value == id) {
                                item.children = data;
                            }
                        })
                    } else {
                        this.categoryOptions[0].children.forEach(item=>{
                            if(item.value == id) {
                                item.children = data;
                            }
                        })
                    }
                } else {
                    this.categoryOptions[0].children.forEach(item=>{
                        if(item.id == value[1].id) {
                            item.children.forEach(it=>{
                                if(it.id == value[2].id) {
                                    it.children = data;
                                }
                            })
                        }
                    })
                }
                
            })
        },
        handleCk(){
            if(!this.stock) {
                this.$message.error('请输入库存数量！')
                return false
            }
            request(process.env.VUE_APP_API_BASE_URL + 'srm/goodsSpec/editStock', 'post', {id:this.editId,stock:this.stock-0}).then(res => {
                if (res.data.code == 200) {
                    this.openKC = false;
                    this.$message.success('修改成功！')
                    this.getData()
                } else {
                    this.$message.error(res.data.message)
                }
            })
        },
        callback(e){
            this.queryData.auditStatus = e;    
            this.getData();
        },
        dateChange(date,dateString) {
            this.dateValue = date;
            this.queryData.dateMin = dateString[0]
            this.queryData.dateMax = dateString[1]
        },
        fileLink(url) {
            window.open(url, '_blank')
        },
        showModal(data) {
          this.openKC = true;
          this.oldstock  = data.stock
          this.editId  = data.id;
          this.stock = ""
        },     
        editStatus(data){
            if(data.status == 2) {
                request(process.env.VUE_APP_API_BASE_URL + 'srm/goodsSpec/editStatus', 'post', {
                    id:data.id,reason:"",status:1
                }).then(res => {
                    if (res.data.code == 200) {
                        this.$message.success('上架成功！')
                        this.getData()
                    } else {
                        this.$message.error(res.data.message)
                    }
                })
            } else {
                this.editId = data.id
                this.downOpen = true
            }
            
        },
        handleDown(){
            if(!this.reason) {
                this.$message.error('请输入下架原因！')
                return false;
            }
            request(process.env.VUE_APP_API_BASE_URL + 'srm/goodsSpec/editStatus', 'post', {
                    id:this.editId,reason:this.reason,status:2
                }).then(res => {
                    if (res.data.code == 200) {
                        this.$message.success('下架申请已提交！')
                        this.reason =""
                        this.downOpen = false;
                        this.getData()
                    } else {
                        this.$message.error(res.data.message)
                    }
                })
        },
        getUserInfo(id){
            request(process.env.VUE_APP_API_BASE_URL + 'srm/qualification/detail/'+id, 'post', {}).then(res => {
                this.userInfo = res.data.data;
                this.formData = this.userInfo.qualificationFileList[0]
                this.isModalVisible = true; 
            })
        },
        handleCancel() {
            this.isModalVisible = false; // 取消操作
        },
        submit(data) {
            // 100 公司资质 200 品牌资质 300厂商资质
            data.type = 100
            if (this.userInfo.id) {
                data.id = this.userInfo.id
                data.companyId = this.userInfo.companyId
            }
            if (data.id) {
                request(process.env.VUE_APP_API_BASE_URL + 'srm/qualification/update', 'post', data).then(res => {
                    if (res.data.code == 200) {
                        this.getData();
                        this.$message.success('更新成功！')
                        this.isModalVisible = false;
                        this.formData = {}
                    }
                })
            } else {
                request(process.env.VUE_APP_API_BASE_URL + 'srm/qualification/save', 'post', data).then(res => {
                    if (res.data.code == 200) {
                        this.getData();
                        this.$message.success('保存成功！')
                        this.isModalVisible = false;
                        this.formData = {}
                    }
                })
            }
        },
        updateStatus(id, status) {
            request(process.env.VUE_APP_API_BASE_URL + 'srm/warehouse/updateStatus', 'post', {
                id, status
            }).then(res => {
                if (res.data.code == 200) {
                    this.getData();
                    this.$message.success('操作成功！')
                    this.isModalVisible = false;
                }
            })

        },
        onShowSizeChange(current, pageSize) {
            this.queryData.size = pageSize;
            this.getData()
        },
        handlePageChange(page) {
            this.queryData.page = page - 1;
            this.currentPage = page;
            this.getData()
        },
        hasPermission(permission) {
            return this.userPermissions.includes(permission);
        },
        reset() {
            this.queryData = {
                page: 0,
                size: 10,
                auditStatus:this.queryData.auditStatus
            }
            this.dateValue = ''
            this.classifyList = []
            this.getData()
        },
        search() {
            this.queryData.page = 0;
            if (this.classifyList.length > 0) {
                let classify = JSON.parse(JSON.stringify(this.classifyList))
                classify.splice(0, 1)
                this.queryData.classifyType = 1;
                this.queryData.classifyList = classify
            }
            this.getData();
        },
        // 获取列表 
        getData() {
            this.tableLoading = true;
            request(process.env.VUE_APP_API_BASE_URL + 'srm/goodsSpec/list', 'post', this.queryData).then(res => {
                this.tableLoading = false;
                let data = res.data.data;
                if (res.data.code == 200) {
                    this.dataSource = data.data;
                    this.queryData.page = data.page;
                    this.queryData.size = data.size;
                    this.total = data.total;
                } else {
                    this.$message.error(res.data.message)
                }
            })
        },
        tabPageChange(page) {
            this.queryData.page = page - 1;
            this.tabCurrent = page;
            this.getData()
        },
        exportTask() {
            this.$message.loading('处理中')
            let params = this.queryData;
            params.remarks = ''
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/goods/cake/exportBrand', 'post', params).then(res => {
                if (res.data.code == 200) {
                    this.$message.success('导出成功！')
                } else {
                    this.$message.error(res.data.message)
                }
            })
        },
        // 格式化年月日
        formatDate(timestamp) {
            const date = new Date(timestamp);
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            // const hours = date.getHours().toString().padStart(2, '0');
            // const minutes = date.getMinutes().toString().padStart(2, '0');
            // const seconds = date.getSeconds().toString().padStart(2, '0');
            // let result = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes;
            let result = year + '-' + month + '-' + day
            return result
        },
        formatDateMin(timestamp) {
            const date = new Date(timestamp);
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            const seconds = date.getSeconds().toString().padStart(2, '0');
            let result = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
            return result
        },
    }
}
</script>

<style lang="less" scoped>
.search {
    // margin-bottom: 54px;
}

.fold {
    width: calc(100% - 216px);
    display: inline-block
}

.operator {
    margin-bottom: 18px;
}

@media screen and (max-width: 900px) {
    .fold {
        width: 100%;
    }
}

::v-deep .page-content {
    padding: 0 !important;
}

.cre-btn {
    width: 100%;
    height: 64px;
    line-height: 64px;
    background: #EEECFE;
    border-radius: 4px;
    border: 1px dashed #5542F6;
    cursor: pointer;
    font-size: 16px;
    font-family: PingFangHK-Regular, PingFangHK;
    font-weight: 400;
    color: #5542F6;
}

.btn-box button {
    width: 106px;
    height: 36px;
    margin-left: 10px;
}


.page-box {
    display: flex;
    justify-content: right;
    padding-right: 46px;
    margin-top: 10px;
}
</style>
